// Content update Dec. 20, 2024
import React, { Fragment } from 'react'

import SEO from '../components/seo'
import PageBuilder from '../components/PageBuilder'

const DefaultPage = ({ pageContext }) => {
  const { metaDescription, metaKeywords, title, node_locale } = pageContext
  return (
    <Fragment>
      <SEO
        description={metaDescription}
        keywords={metaKeywords}
        title={title}
        lang={node_locale}
      />
      <PageBuilder {...pageContext} />
    </Fragment>
  )
}

export default DefaultPage
