import React, { useState, Fragment, useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import _ from 'lodash'
import MenuItemUI from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import LanguageContext from '../../Context'

import { arrayHasEntry } from '../../../utils'
import MenuItem from '../../MenuItem'
import Image from '../Image'
import LinkWithIcon from '../LinkWithIcon'
import ToggleImageIcon from './location-toggle-icon.png'

const AppBar = styled.div`
  border-top: 0.5px solid #ccccd6;
  border-bottom: 0.5px solid #ccccd6;
  color: #454d51;
  display: flex;
  .gatsby-outer-img-wrapper {
    width: 200px;
    display: inline-block;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const NavBarLogoWrapper = styled.div`
  border-right: 0.5px solid #ccccd6;
  flex: 0 0 33.333%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    height: 72px;
  }
  @media screen and (max-width: 769px) {
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }
  .language-switcher {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
`

const NavBarLinksWrapper = styled.div`
  display: flex;
  flex: 1 1 66.666%;
  flex-direction: column;
`

const NavBarLinksTop = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavBarLinksBottom = styled.div`
  display: flex;
`
const NavBarItemWrapper = styled.div`
  border-bottom: 0.5px solid #ccccd6;
  padding: 5px 10px;
  flex: 1 1 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 20px;
  &:first-child {
    flex: 1 1 40%;
  }
  &:last-child {
    flex: 1 1 20%;
    border-left: 0.5px solid #ccccd6;
    border-right: 0.5px solid #ccccd6;
  }
  :not(:nth-child(4)) {
    img {
      display: none;
    }
  }
  &:nth-child(4) {
    flex: 1 1 40%;
    > div {
      background-color: #d3263c;
      color: #ffffff;
      border-radius: 40px;
      margin: 5px;
      padding: 10px 10px;
      font-size: 14px;
      font-weight: 500;
      align-items: center;
      img {
        max-height: 25px;
        max-width: 25px;
      }
      a {
        text-align: center;
      }
    }
  }
  img {
    max-height: 20px;
    width: auto;
    max-width: 20px;
  }
`

const NavBarBottomItemWrapper = styled.div`
  padding: 0 10px;
  flex: 1 1 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 21px;
  color: #e13426;
  text-transform: uppercase;
  &:first-child {
    flex: 1 1 40%;
  }
`

const Mobile = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
`

const LanguageSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 25px;
    max-height: unset;
    max-width: unset;
    height: auto;
    margin-right: 5px;
  }
`

const useStyles = makeStyles({
  root: {
    fontFamily: 'Poppins,sans-serif',
  },
  option: {
    backgroundColor: 'red',
  },
  select: {
    fontFamily: 'Poppins,sans-serif',
    fontSize: '13px',
  },
  menu: {
    '& div:nth-child(3)': {
      boxShadow: 'unset',
      borderRadius: '0px',
      border: '1px solid grey',
      width: '50px',
    },
    '& li': {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '13px',
      '&.Mui-selected': {
        display: 'none',
      },
    },
  },
})

const LanguageSelector = props => {
  const classes = useStyles()
  const handleChange = () => {
    dispatchLanguageState({ type: 'toggle' })
  }

  const { languageState, dispatchLanguageState } = useContext(LanguageContext)

  return (
    <div>
      <LanguageSelectorWrapper>
        <img alt="" src={ToggleImageIcon} />
        <FormControl className={classes.root}>
          <Select
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              className: classes.menu,
            }}
            className={classes.select}
            id={props.mobile === true ? 'mobile' : ''}
            value={languageState.lang === true ? 'EN' : 'FR'}
            onChange={handleChange}
            disableUnderline
            aria-label="Language Toggle Switch"
          >
            <MenuItem
              disabled
              value={languageState.lang === true ? 'EN' : 'FR'}
            >
              {languageState.lang === true ? 'EN' : 'FR'}
            </MenuItem>
            {languageState.lang === false ? (
              <MenuItemUI aria-label="Switch to English" value={'EN'}>
                EN
              </MenuItemUI>
            ) : (
              <MenuItemUI aria-label="Switch to French" value={'FR'}>
                FR
              </MenuItemUI>
            )}
          </Select>
        </FormControl>
      </LanguageSelectorWrapper>
    </div>
  )
}

function NavBarMenu({ menuItems }) {
  const [openMenu, setOpenMenu] = useState('')
  return (
    <Fragment>
      {arrayHasEntry(menuItems) &&
        menuItems.map((menuItem, menuIndex) => (
          <NavBarBottomItemWrapper key={`menuItem-${menuItem.id}`}>
            <MenuItem
              enableDropdown
              openMenu={openMenu === menuItem.id}
              setOpenMenu={(menuItemId = '') => setOpenMenu(menuItemId)}
              link={_.get(menuItem, 'link.slug') || null}
              {...menuItem}
            />
          </NavBarBottomItemWrapper>
        ))}
    </Fragment>
  )
}

function NavBarItem({
  __typename,
  image,
  linkImage,
  menuItems,
  title,
  link,
  props,
}) {
  const { languageState } = useContext(LanguageContext)
  switch (__typename) {
    case 'ContentfulComponentImage':
      return (
        <Link
          aria-label="Select to return to home"
          to={languageState.lang === true ? '/' : '/fr/'}
        >
          <Image title={title} {...image} />
        </Link>
      )
    case 'ContentfulComponentLink':
      if (linkImage) {
        return (
          <LinkWithIcon
            externalLink={_.get(props, 'externalLink', '')}
            linkImage={linkImage}
            title={title}
            link={link}
          />
        )
      }
      break
    case 'ContentfulComponentMenu':
      return <NavBarMenu menuItems={menuItems} />
    default:
      break
  }
  return null
}

export default function({ navBarItems }) {
  const siteLogo = navBarItems[0]
  const topLinks = [...navBarItems].splice(1, navBarItems.length - 2)
  const topMenu = navBarItems[navBarItems.length - 1]
  return (
    <AppBar className={`app-bar`}>
      <NavBarLogoWrapper>
        <NavBarItem {...siteLogo} />
        <Mobile>
          <LanguageSelector mobile />
        </Mobile>
      </NavBarLogoWrapper>
      <NavBarLinksWrapper>
        <NavBarLinksTop>
          {[...topLinks].splice(0, 3).map((navBarItem, itemIndex) => (
            <NavBarItemWrapper key={`navBarItem-${navBarItem.id}`}>
              <NavBarItem {...navBarItem} />
            </NavBarItemWrapper>
          ))}
          {[...topLinks].splice(3, 4).map((navBarItem, itemIndex) => (
            <NavBarItemWrapper key={`navBarItem-${navBarItem.id}`}>
              <NavBarItem {...navBarItem} />
            </NavBarItemWrapper>
          ))}
          <NavBarItemWrapper>
            <LanguageSelector></LanguageSelector>
          </NavBarItemWrapper>
        </NavBarLinksTop>
        <NavBarLinksBottom>
          <NavBarItem {...topMenu} />
        </NavBarLinksBottom>
      </NavBarLinksWrapper>
    </AppBar>
  )
}
